<template>
  <div>
    <div>
      <navbar title="联系我们"></navbar>
      <div class="box">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    ...mapActions(["getcontactUs"]),
  },
  mounted() {
    this.getcontactUs({}).then((res) => {
      this.content = res.ResponseBody.content;
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0 0.2rem;
  box-sizing: border-box;
}
</style>